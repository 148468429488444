import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const DEFAULT_TITLE = 'Mevius';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "history" */ '../views/History.vue'),
    meta: {bannedAllowed: true, unauthorizedAllowed: true},
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {unauthorizedAllowed: true},
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import(/* webpackChunkName: "reset" */ '../views/Reset.vue'),
    props: {step: 1},
    meta: {unauthorizedAllowed: true}
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    props: true,
    meta: {unauthorizedAllowed: true},
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import(/* webpackChunkName: "feedback" */ '../views/Feedback.vue'),
    meta: {bannedAllowed: true, unauthorizedAllowed: true},
  },
  {
    path: '/register/final',
    name: 'registerFinal',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    props: {step: 6},
    meta: {requiresRegisterFinal: true, unauthorizedAllowed: true}
  },
  {
    path: '/register/info-about-passport-check',
    name: 'info-passport-check',
    component: () => import(/* webpackChunkName: "info-passport-check" */ '../views/InfoAboutPassportCheck.vue'),
    meta: {requiresAuth: false, unauthorizedAllowed: true}
  },
  {
    path: '/register/some-info',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {requiresAuth: false, unauthorizedAllowed: true},
    children: [{
      path: '',
      name: 'profile-fields',
      component: () => import(/* webpackChunkName: "profileForm" */ '../components/profile/ProfileForm.vue')
    }]
  },
  {
    path: '/register/consumption-survey',
    name: 'register-consumption-survey',
    component: () => import(/* webpackChunkName: "consumptionSurvey" */ '../views/ConsumptionSurvey.vue'),
    meta: {requiresAuth: false, unauthorizedAllowed: true},
  },
  {
    path: '/products/:id',
    name: 'products-detail',
    component: () => import(/* webpackChunkName: "productsDetail" */ '../views/ProductsDetail.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/blocked',
    name: 'blocked',
    component: () => import(/* webpackChunkName: "blocked" */ '../views/Blocked.vue'),
    meta: {bannedAllowed: true},
  },
  {
    path: '/cabinet',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profileForm" */ '../components/profile/ProfileForm.vue'),
      },
      {
        path: '/cabinet/update',
        name: 'profile-update',
        component: () => import(/* webpackChunkName: "profileUpdate" */ '../components/profile/ProfileUpdate.vue'),
      },
      {
        path: '/cabinet/documents',
        name: 'profile-documents',
        component: () => import(/* webpackChunkName: "profileDocuments" */ '../components/profile/ProfileDocuments.vue'),
      },
      {
        path: '/cabinet/password',
        name: 'profile-password',
        component: () => import(/* webpackChunkName: "profilePassword" */ '../components/profile/ProfilePassword.vue'),
      },
      {
        path: '/cabinet/delete',
        name: 'profile-delete',
        component: () => import(/* webpackChunkName: "profileDelete" */ '../components/profile/ProfileDelete.vue'),
      },
      {
        path: '/cabinet/subscribe',
        name: 'profile-subscribe',
        component: () => import(/* webpackChunkName: "profileSubsctibe" */ '../components/profile/ProfileSubscribe.vue'),
      },
      {
        path: '/cabinet/activity',
        name: 'activity',
        component: () => import(/* webpackChunkName: "profileActivity" */ '../components/profile/ProfileActivity.vue'),
      }
    ],
    meta: {requiresAuth: true},
  },
  {
    path: '/consumption-survey',
    name: 'consumption-survey',
    props: true,
    component: () => import(/* webpackChunkName: "consumptionSurvey" */ '../views/ConsumptionSurvey.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/email-confirm',
    name: 'email-confirm',
    component: () => import(/* webpackChunkName: "emailConfirm" */ '../views/EmailConfirm.vue'),
    meta: {unauthorizedAllowed: true},
  },

  {
    path: '/archive/portal/',
    name: 'archive-main',
    component: () => import(/* webpackChunkName: "portalMain" */ '../views/PortalMainArchive.vue'),
    meta: {
      title: 'Портал(Архив)',
    },
  },
  {
    path: '/portal',
    name: 'portal-main',
    component: () => import(/* webpackChunkName: "portalMain" */ '../views/PortalMain.vue'),
    meta: {
      title: 'Портал',
    },
  },
  {
    path: '/portal/surveys',
    name: 'portal-surveys',
    component: () => import(/* webpackChunkName: "portalSurveyList" */ '../views/PortalQuestionnaire.vue'),
    meta: {
      title: 'Опросы',
    },
  },
  {
    path: '/archive/portal/surveys',
    name: 'archive-surveys',
    component: () => import(/* webpackChunkName: "portalSurveyList" */ '../views/PortalQuestionnaire.vue'),
    meta: {
      title: 'Опросы',
    },
  },
  {
    path: '/portal/surveys/:code',
    name: 'portal-survey-detail',
    component: () => import(/* webpackChunkName: "portalSurveyDetail" */ '../views/PortalSurvey.vue'),
  },
  {
    path: '/portal/articles',
    name: 'portal-articles',
    component: () => import(/* webpackChunkName: "portalArticles" */ '../views/PortalArticles.vue'),
    meta: {
      title: 'Статьи',
    },
  },
  {
    path: '/archive/portal/articles',
    name: 'archive-articles',
    component: () => import(/* webpackChunkName: "portalArticles" */ '../views/PortalArticles.vue'),
    meta: {
      title: 'Статьи',
    },
  },
  {
    path: '/portal/articles/:code',
    name: 'portal-articles-detail',
    component: () => import(/* webpackChunkName: "portalArticles" */ '../views/ArticleDetail.vue'),
  },
  {
    path: '/portal/tests',
    name: 'portal-tests',
    component: () => import(/* webpackChunkName: "portalTest" */ '../views/PortalTest.vue'),
    meta: {
      title: 'Тесты',
    },
  },
  {
    path: '/archive/portal/tests',
    name: 'archive-tests',
    component: () => import(/* webpackChunkName: "portalTest" */ '../views/PortalTest.vue'),
    meta: {
      title: 'Тесты',
    },
  },
  {
    path: '/portal/tests/:code',
    name: 'portal-tests-detail',
    component: () => import(/* webpackChunkName: "portalMain" */ '../views/TestDetail.vue'),
  },
  {
    path: '/portal/test/item',
    name: 'portal-test-detail-item',
    component: () => import(/* webpackChunkName: "portalMain" */ '../views/TestDetailItem.vue'),
  },
  {
    path: '/portal/nonograms',
    name: 'portal-nonograms',
    component: () => import(/* webpackChunkName: "NonogramDetail" */ '../views/NonogramDetail.vue'),
    meta: {
      title: 'Нонограммы',
    },
  },
  {
    path: '/portal/nonograms/:code',
    name: 'portal-nonograms-detail',
    component: () => import(/* webpackChunkName: "NonogramDetail" */ '../views/NonogramDetail.vue'),
  },
  /*{
    path: '/portal/confidentiality',
    name: 'portal-confidentiality',
    component: () => import(/!* webpackChunkName: "PortalConfidentiality" *!/ '../views/PortalConfidentiality.vue'),
  },*/
  {
    path: '/new-year/ny2023',
    name: '2023',
    component: () => import(/* webpackChunkName: "NewYear2023" */ '../views/NewYear2023.vue'),
  },
  {
    path: '/history',
    name: 'history',
    component: () => import(/* webpackChunkName: "history" */ '../views/History.vue'),
    meta: {bannedAllowed: true, unauthorizedAllowed: true},
  },
  {
    path: "*",
    name: '404',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/404.vue'),
    meta: {unauthorizedAllowed: true},
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/', //process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
});

router.beforeEach((to, from, next) => {
  const isAuthorized = store.state.isAuthenticated;
  const isBlocked = store.state.userBanned;
  const segments = store.state.currentUser.segments;

  if (segments?.ProductSiteOnly && to.name !== 'products-detail') {
    next({path: '/products/compact-crush'});
  } else if (isBlocked && !to.matched.some(route => route.meta.bannedAllowed)) {

    next({name: 'blocked'});
  } else if (store.state.currentUser && to.name === 'login') {
    next({name: 'register'});
  } else if (
    !isBlocked
    && !isAuthorized
    && !to.matched.some(route => route.meta.unauthorizedAllowed)
  ) {
    next({ name: 'login', query: { next: to.fullPath } });
  } else if (
    (to.matched.some(route => route.meta.requiresAuth) && !store.state.isAuthenticated)
    || (isAuthorized && (
      to.name === 'register'
      || to.name === 'login'
      || to.name === 'reset'
    ))
  ) {
    next({name: 'home'});
  } else {
    next();
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
