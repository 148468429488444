<template>
  <div class="profile-update">
    <my-modal>
      <form v-if="!sendSuccess" class="form" @submit.prevent="submit">
        <h2 class="form__title">Личные данные</h2>
        <div class="form-row form-row_2-cols form-row_mobile-no-flex">
          <div>
            <text-input
              label="Фамилия"
              v-model="profile.lastName"
              name="lastName"
            />
            <text-input
              label="Имя"
              v-model="profile.firstName"
              name="firstName"
            />
          </div>
          <div>
            <text-input
              label="Отчество"
              v-model="profile.middleName"
              name="middleName"
            />
            <div class="text-input text-input_date" :class="{
            'text-input_filled': profile.birthDate.length,
            'text-input_focused': dateFocus,
            'text-input_invalid': $v.profile.birthDate.$error
            }">
              <label class="text-input__label"> Дата рождения* </label>
              <date-picker
                :value="profile.birthDate"
                @input="profile.birthDate = $event"
                format="DD-MM-YYYY"
                titleFormat="DD-MM-YYYY"
                valueType="DD-MM-YYYY"
                type="date"
                time-title-format="DD-MM-YYYY"
                :clearable="false"
                :default-value="adultBirthDateInit"
                :disabled-date="adultBirthDate"
                @open="dateFocus = true"
                @close="dateFocus = false"
              >
                <template #input>
                  <input :value="profile.birthDate"
                         @blur="$v.profile.birthDate.$touch()"
                         @input="profile.birthDate = $event.target.value"
                         type="text"
                         class="text-input__field _date"
                         v-mask="'##-##-####'"
                  >
                </template>
              </date-picker>
              <div class="text-input__error" v-if="$v.profile.birthDate.$error">
                <div v-if="!$v.profile.birthDate.required">Заполните поле</div>
                <div v-if="$v.profile.birthDate.required && !$v.profile.birthDate.availableDate">Некорректная дата</div>
              </div>
            </div>
          </div>

        </div>
        <div class="form-row form-row_2-cols form-row_mobile-no-flex">
          <div class="form-scroll">
            <div class="form-scroll__content">
              <div class="sign-up-terms">
                Согласие на обработку персональных данных
                <p class="sign-up-terms__text">
                  Я подтверждаю, что являюсь совершеннолетним потребителем никотинсодержащей продукции, и добровольно,
                  своей волей и в своем интересе - ставя отметку в графе «да» или расписываясь в графе «Подпись» - я даю
                  полное и безоговорочное согласие<br/>
                  Обществу с ограниченной ответственностью «Дж.Т.И. Россия» (123112, Москва, проезд 1-й
                  Красногвардейский, д. 15, этажи 28-32), далее – «Компания»,<br/>
                  а также иным уполномоченным лицам Компании, включая, но не ограничиваясь,<br/>
                  Обществу с ограниченной ответственностью «Аутдор Медиа Интернэшнл» (ООО «ОМИ», 121357, г. Москва, ул.
                  Верейская, д.5Б, стр.1, комната 8), <br/>
                  Обществу с ограниченной ответственностью «Майндбокс» (ООО «Майндбокс», 125124, г. Москва, ул. Правды,
                  д. 26, 12 этаж),<br/>
                  Обществу с ограниченной ответственностью «Системы управления идентификацией» (ООО «СУИ», 101000, г.
                  Москва, ул. Мясницкая, д. 22, стр. 1, комната 6),<br/>
                  Общество с ограниченной ответственностью «Емейл Солджерс» (ООО «Емейл Солджерс», 390000, г. Рязань,
                  улица Николодворянская, дом 18, пом Н1),<br/>
                  Публичному акционерному обществу «МОБИЛЬНЫЕ ТЕЛЕСИСТЕМЫ» (ПАО «МТС», 109147, г. Москва, ул.
                  Марксистская, 4),<br/>
                  Публичному акционерному обществу «ВЫМПЕЛ-КОММУНИКАЦИИ» (ПАО «ВымпелКом», 127083, г. Москва, ул. 8
                  Марта, д. 10, строение 14),<br/>
                  Публичному акционерному обществу «МЕГАФОН» (ПАО «Мегафон», 127006, г. Москва, пер. Оружейный, д. 41)
                  Обществу с ограниченной ответственностью «Т2 Мобайл» (ООО «Т2 Мобайл», 108811, г. Москва, п.
                  Московский, Киевское шоссе 22-й км, домовладение 6, строение 1, этаж 5, комната 33),<br/>
                  Обществу с ограниченной ответственностью «Ипсос Комкон» (ООО «Ипсос Комкон», 107140, г. Москва, ул.
                  Верхняя Красносельская, д. 3, стр. 2),<br/>
                  на осуществление обработки (в том числе по поручению), включая сбор, хранение, использование,
                  уничтожение, систематизацию, уточнение (обновление, изменение) смешанным способом моих персональных
                  данных: фамилия, имя, отчество, дата рождения, пол, абонентский номер телефона, адрес электронной
                  почты (если указано), почтовый адрес (если указано), паспортные данные и номер / фото ИНН (если
                  указано), номер /фото СНИЛС (если указано), номер/фото водительского удостоверения (если указано),
                  основная и вторая марки сигарет, с целью формирования базы данных потребителей, в том числе
                  предоставления потребителям доступа на сайт и участия в проводимых Компанией программах, мероприятиях
                  и иных активностях в том числе доступных на сайте/сайтах, и взаимодействия в рамках указанных
                  программ, мероприятий и активностей, составления статистической отчетности и аналитики данных об
                  активностях потребителей для предоставления релевантной информации и повышения качества обслуживания,
                  проведения опросов, исследований, направления мне информационных сообщений, информации о новых
                  товарах, осуществления прямых контактов со мной, в том числе, по сетям электросвязи, включая
                  направление SMS-сообщений и электронной почты, а также посредством использования программного
                  обеспечения и сервисов для обмена сообщениями, в том числе при организации работы «Горячей линии»,
                  сервисов «Обратной связи» и др., по любым вопросам в отношении продукции и потребительскому
                  использованию продуктов, а также подтверждения принадлежности мне указанных выше персональных данных у
                  операторов персональных данных, в том числе, у бюро кредитных историй, операторов подвижной связи,
                  операторов электронного документооборота, государственных органов, учреждений и организаций,
                  осуществляющих ведение государственных реестров (включая, оператора Единой системы идентификации и
                  аутентификации, Пенсионный фонд РФ, Министерство внутренних дел). Я подтверждаю и гарантирую, что
                  предоставленные мной персональные данные соответствуют действительности и являются актуальными,
                  полными и точными, я уведомлен и согласен, что проверка моих персональных данных производится для
                  подтверждения принадлежности мне указанных мной данных и моего совершеннолетия и не влечет доступ к
                  другим моим персональным данным или обработку каких-либо других персональных данных.<br/>
                  Настоящее согласие действует до достижения цели обработки персональных данных и может быть отозвано
                  мной в любое время одним из следующих способов: 1) направив письмо с указанием фамилии, имени,
                  отчества и адреса, указанных при регистрации, на адрес электронной почты contact@jdata.ru, 2) в
                  разделе «Личный кабинет» на сайте, где производилась регистрация, 3) направив письменный запрос в
                  адрес Компании, указанный выше.<br/>
                  Я обязуюсь незамедлительно информировать, если предоставленные мной персональные данные утрачивают
                  актуальность или изменяются.<br/>
                  Я согласен с тем, что предоставленные мной персональные данные будут удалены по моему требованию в
                  течение 60 (шестидесяти) дней с даты получения требования об их уничтожении. <br/>
                  Я подтверждаю ознакомление с Политикой Конфиденциальности и принимаю ее условия.
                </p>
              </div>
            </div>
          </div>
          <sign-input v-model="profile.personalDataAgreementSignature"/>
        </div>

        <div v-if="isShowPassport" class="form-row form-row_no-flex">
          <div class="form__description scan">
            Загрузите скан/фото 2, 3 и 5 страницы паспорта*
          </div>
          <file-input input-name="passport" @change="onChangePassport">
            <template #title>
              Разворот с фотографией
            </template>
            <template #errors>
              <div class="fileinput__error" v-if="$v.profile.passport.$error">
                <div v-if="!$v.profile.passport.required">Выберите файл</div>
              </div>
            </template>
          </file-input>
          <p class="form__footnote form__footnote_v2">
            Для верификации пользовательских данных на&nbsp;сайте просим Вас
            предоставить фото или&nbsp;сканированную копию паспорта. Обращаем
            Ваше внимание, что указанные данные необходимы для целей
            подтверждения указанных Вами в&nbsp;анкете данных и&nbsp;их
            принадлежности Вам. Фотографическое изображение, содержащееся
            в&nbsp;паспорте, не&nbsp;используется. При сканировании страниц
            паспорта закройте, пожалуйста, Вашу фотографию в&nbsp;документе.
          </p>
          <p class="form__footnote">
            *Размер загружаемого файла не должен превышать 10 Мб
          </p>
        </div>

        <div
          class="form-row form-row_margin-top-md form-row_align-center form-row_mobile-wrap"
        >
          <button
            class="form__submit button button__lg button__blue"
            type="submit"
            :disabled="$v.$invalid"
          >
            Отправить
          </button>
        </div>
        <div class="form-row form-row_margin-top-md form-row_align-center">
          <router-link :to="{ name: 'profile' }" class="form__link">
            Назад
          </router-link>
        </div>
      </form>
      <div v-else>
        <h2 class="form__title">
          Благодарим вас <br/>за&nbsp;обновление данных
        </h2>
        <div
          class="form__description form__description_align-center"
          style="max-width: 420px"
        >
          Вознаграждение поступит в&nbsp;течение 3&nbsp;дней с&nbsp;момента
          подтверждения указанных Вами данных.*
        </div>
        <p class="form__footnote form__footnote_border-top _g-text-center">
          *вознаграждение можно получить только один раз.
        </p>
        <div class="form-row form-row_margin-top-md form-row_align-center">
          <router-link :to="{ name: 'profile' }" class="form__link">
            Назад
          </router-link>
        </div>
      </div>
    </my-modal>
    <modal
      ref="notifyModal"
      :show.sync="isShowNotify"
      align-center
      :max-width="656"
      class-name="modal_notify"
    >
      <h2
        v-if="notify.title"
        class="form__title"
        style="max-width: 325px; margin: 0 auto 18px"
        v-html="notify.title"
      />
      <div
        v-if="notify.text"
        class="form__description"
        style="max-width: 420px"
        v-html="notify.text"
      />
      <p v-if="notify.note" class="form__footnote" v-html="notify.note" />
      <div class="form__footer">
        <button class="form__link" @click="closeNotify">Закрыть</button>
      </div>
    </modal>
  </div>
</template>

<script>
import MyModal from "../MyModal";
import TextInput from "../TextInput";
import {required, requiredIf, helpers} from "vuelidate/lib/validators";
import api from "../../services/api";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/index.css';
import {VueMaskDirective} from "v-mask";

const adultBirthDate = (value) => {
  const minVal = new Date().setFullYear(new Date().getFullYear() - 18);
  const maxVal = new Date().setFullYear(new Date().getFullYear() - 99);
  return value > minVal || value < maxVal;
};

const availableDate = (value) => {
  if (helpers.req(value)) {
    if (!(value.length < 10)) {
      let formatDate = value.split('-');
      formatDate = [formatDate[1], formatDate[0], formatDate[2]];
      return !adultBirthDate(new Date(formatDate.join('-'))) &&
        !isNaN(new Date(new Date(formatDate.join('/'))).getTime());
    } else return false;
  } else return false;
};

export default {
  name: "ProfileUpdate",
  components: {TextInput, MyModal, DatePicker},
  directives: {
    mask: VueMaskDirective
  },
  data() {
    return {
      dateFocus: false,
      profile: {
        lastName: "",
        firstName: "",
        middleName: "",
        birthDate: "1990-01-01",
        passport: null,
        personalDataAgreementSignature: '',
      },
      sendSuccess: false,
      isShowNotify: false,
      notify: {
        title: "",
        text: "",
        note: ""
      }
    };
  },
  validations() {
    return {
      profile: {
        lastName: {required},
        firstName: {required},
        middleName: {required},
        birthDate: {required, availableDate},
        passport: {required: requiredIf(() => this.isShowPassport)},
        personalDataAgreementSignature: {},
      },
    };
  },
  computed: {
    adultBirthDateInit() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 18);
      return date;
    },
    profileData() {
      return this.$store.state.profileData;
    },
    segments() {
      return this.$store.state.userSegments;
    },
    isShowPassport() {
      return (typeof this.profileData.customFields.idxAdditionalPersonalDataCheck === 'undefined'
          || this.profileData.customFields.idxAdditionalPersonalDataCheck === null)
        && (this.profileData.customFields.idxchechbhfullname === false)
    },
  },
  async mounted() {
    await this.updateProfileData();
    await this.fetchUserSegments();
  },
  methods: {
    adultBirthDate(value) {
      return adultBirthDate(value);
    },
    async submit() {
      this.$v.$touch();
      if (!this.$v.$pending && !this.$v.$invalid) {
        try {
          if (!this.isShowPassport) {
            const verifyAge = await api.verifyAge(this.profile);

            if (verifyAge) {
              await api.updateCustomFields({
                passportWasCheck: false,
                idxCheckFullName: true,
                idxAdditionalPersonalDataCheck: true,
                pDataAgreementSignature: this.profile.personalDataAgreementSignature,
              });
              this.showNotify({
                title: "Благодарим вас за&nbsp;обновление данных",
                text: "<p>Вознаграждение поступит в течение 3 дней с момента подтверждения указанных Вами данных.*</p>",
                note: "*Вознаграждение можно получить только один раз"
              });
            } else {
              await api.updateCustomFields({
                idxCheckFullName: false,
              });
            }

            await this.$store.dispatch('fetchProfile');
            this.$v.$touch();
            await this.updateProfileData();
            await this.$store.dispatch('hideLoader');
            return;
          }

          await this.$store.dispatch("showLoader");
          let result = await api.verifyPersonNew(this.profile);

          if (result) {
            const result = await api.updateProfileNew(this.profile);
            if (result) {
              /*const b = await api.updateCustomFields({
                passportWasCheck: true,
                idxCheckFullName: true,
                idxAdditionalPersonalDataCheck: true,
                pDataAgreementSignature: this.profile.personalDataAgreementSignature,
              });*/
              this.$emit('complete', result);
              this.showNotify({
                title: "Благодарим вас за&nbsp;обновление данных",
                text: "<p>Вознаграждение поступит в течение 3 дней с момента подтверждения указанных Вами данных.*</p>",
                note: "*Вознаграждение можно получить только один раз"
              });
            } else {
              console.log(result);
            }
          }
        } catch (e) {
          await this.$store.dispatch('setErrors', e);
        } finally {
          await this.$store.dispatch('fetchProfile');
          await this.$store.dispatch('hideLoader');
        }
      }
    },
    onChangePassport(file) {
      this.profile.passport = file;
    },
    async updateProfileData() {
      Object.keys(this.profile).forEach(
        (key) => {
          if (key === 'birthDate') {
            this.profile[key] = this.$options.filters.dateFilter(this.profileData[key])
          } else {
            this.profile[key] = this.profileData[key] || this.profile[key];
          }
        }
      );
    },
    async fetchUserSegments() {
      await this.$store.dispatch('fetchUserSegments');
    },
    closeNotify() {
      this.$refs.notifyModal.close();
    },
    showNotify(notify) {
      this.isShowNotify = true;
      this.notify = notify;
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/base/_settings.scss";

$b: ".profile-update";

#{$b} {
  .form-row {
    > div {
      &:not(:last-child) {
        @include mobile {
          margin-bottom: 16px;
        }
      }
    }

    &_2-cols {
      &._alt {
        > * {
          @include mobile-min {
            &:first-child {
              width: calc(60% - 10px);
            }
            &:last-child {
              width: calc(40% - 10px);
            }
          }
        }
      }
    }
  }

  .text-input {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .form__description {
    &.scan {
      margin: 6px auto 26px;
      font-size: 14px;
    }
  }

  .fileinput {
    border-bottom: none;

    &.empty {
      .fileinput__label {
        display: none;
      }
    }
  }

  .form__footnote {
    &:not(.form__footnote_v2) {
      border-top: 1px solid #e8ebf1;
      padding-top: 12px;
      margin-top: 20px;
    }

    &.form__footnote_v2 {
      margin-top: 0;
    }
  }
}
</style>
