import "./scss/init.scss";

import Vue from 'vue';
import Vuelidate from 'vuelidate';
import Paginate from 'vuejs-paginate'
import VueSignaturePad from 'vue-signature-pad';
import router from './router';
import store from './store';
// import registerVueDirectives from "./directives";
import App from './App';
import VueScrollbar from 'vue2-scrollbar';
import VueScreen from 'vue-screen';


Vue.use(Vuelidate);
Vue.use(VueSignaturePad);
Vue.use(VueScrollbar);
Vue.use(VueScreen);
Vue.component('paginate', Paginate)

// registerVueDirectives();

Vue.config.productionTip = false;

const files = require.context('./components/', true, /\.vue$/i);
files.keys().map(key =>
  Vue.component(
    key
      .split('/')
      .pop()
      .split('.')[0],
    files(key).default
  )
);

Vue.filter('dateFilter', function (val) {
  val = val.split('-');
  return `${val[2]}-${val[1]}-${val[0]}`
});

window.vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
appHeight();
