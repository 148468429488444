<template>
  <div class="footer">
    <div class="container">
      <div class="footer__logo">
        <a href="/">
          <img src="/frontend/assets/i/logo-zen-bay-light.svg" alt=""/>
        </a>
      </div>
      <div v-if="hasCopy" class="footer__copy">© Бухта дзена 2024</div>
      <div class="footer__nav">
        <router-link :to="{ name: 'feedback' }">Обратная связь</router-link>
        <a v-if="false" href="/upload/docs/Terms_and_Conditions_progress.pdf" target="_blank">Правила активации</a>
        <a href="/upload/docs/polit_konf.pdf" target="_blank">Политика конфиденциальности</a>
        <a href="/upload/docs/polit_pers_dann.pdf" target="_blank">Политика по персональным данным</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    hasCopy: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPathPortal() {
      return window.location.pathname === '/portal'
    },
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    }
  }
}
</script>
