import {VctrApi} from "../vectary";
export default {
  props: {
    crushMode: {
      type: Boolean,
      default: false,
    },
    nextButton: {
      type: Object,
      default: () => {
        return { url: '', text: '' }
      }
    }
  },
  data: function () {
    return {
      vectaryApi: null,
      isClosed: true, // индикатор заркытой пачки
      isUp: false, // индикатор "вытащенной" сигареты
      inMove: false,
      activeSection: 0,
      offsets: [],
      touchStartY: 0,
      parallaxTransform: "",
      parallaxTransform2: "",
      animateIndex: 0,
      textHeight: 0,
      containerHeight: 480,
      isScrolling: null,
      show360: true
    };
  },
  computed: {
    headerHeight() {
      return document.querySelector(".header").offsetHeight;
    },
    footerHeight() {
      return document.querySelector(".footer").offsetHeight;
    },
  },
  async mounted() {
    this.calculateSectionOffsets();
    this.scrollToSection(this.activeSection, true);
    document.getElementById("fp").addEventListener('wheel', this.handleMouseWheelDOM);  // Mozilla Firefox
    document.getElementById("fp").addEventListener("mousewheel", this.handleMouseWheel, { passive: false,}); // Other browsers
    document.getElementById("fp").addEventListener("touchstart", this.touchStart, { passive: false }); // mobile devices
    document.getElementById("fp").addEventListener("touchmove", this.touchMove, { passive: false }); // mobile devices
    if (this.crushMode) {
      document.querySelector("body").classList.add("product-crush");
    }
    window.addEventListener('resize', this.resizeEvent);

    this.vectaryApi= new VctrApi("cigarettes-pack-page__model");

    /* temporarily disabled while a placeholder is used
    try {
      await this.vectaryApi.init();
    } catch (e) {
      console.log('Vectary API error');
    }
    */

    this.animatePack();
    setTimeout(()=> {
      this.show360 = false;
    }, 20000)
  },
  beforeDestroy() {
    this.vectaryApi = null;
    document.querySelector("body").classList.remove("product-crush");
    window.removeEventListener('resize', this.resizeEvent)
  },
  methods: {
    calculateSectionOffsets() {
      let sections = document.getElementsByTagName("section");
      let length = sections.length;
      this.offsets = [];
      for (let i = 0; i < length; i++) {
        let sectionOffset = sections[i].offsetTop;
        this.offsets.push(sectionOffset);
      }
    },
    onScrollEnd() {
      let t = this;
      window.clearTimeout(t.isScrolling);
      t.inMove = true;

      this.isScrolling = setTimeout(function () {
        t.inMove = false;
      }, 1000);
    },
    handleMouseWheel: function (e) {
      var isOverflowSection =
        document.getElementsByTagName("section")[this.activeSection]
          .offsetHeight >
        window.innerHeight - this.footerHeight;
      var innerOffset = document
        .getElementsByTagName("section")[this.activeSection].getBoundingClientRect().top;
      if (e.wheelDelta < 0) {
        if (
          isOverflowSection &&
          window.innerHeight < document.getElementById('fp').clientHeight - window.pageYOffset
        ) {
          this.onScrollEnd();
          return;
        } else if (!this.inMove) {
          this.moveUp();
          e.preventDefault();
          return false;
        }
      } else if (e.wheelDelta > 0) {
        if (isOverflowSection && Math.round(innerOffset) !== 0) {
          this.onScrollEnd();
          return;
        } else if (!this.inMove) {
          this.moveDown();
          e.preventDefault();
          return false;
        }
      }

      e.preventDefault();
      return false;
    },
    handleMouseWheelDOM: function (e) {
      var isOverflowSection =
        document.getElementsByTagName("section")[this.activeSection]
          .offsetHeight >
        window.innerHeight - this.footerHeight;
      var innerOffset = document
        .getElementsByTagName("section")[this.activeSection].getBoundingClientRect().top;
      if (e.deltaY > 0) {
        if (
          isOverflowSection &&
          window.innerHeight < document.getElementById('fp').clientHeight - window.pageYOffset
        ) {
          this.onScrollEnd();
          return;
        } else if (!this.inMove) {
          this.moveUp();
          e.preventDefault();
          return false;
        }
      } else if (e.deltaY < 0) {
        if (isOverflowSection && Math.round(innerOffset) !== 0) {
          this.onScrollEnd();
          return;
        } else if (!this.inMove) {
          this.moveDown();
          e.preventDefault();
          return false;
        }
      }

      return false;
    },
    async animatePackPosition(toState, duration = 1000) {
      const currentState = await this.vectaryApi.getViewState();

      VctrApi.Utils.animate(
        duration,
        "easeInOutQuad",
        async (timeFraction) => {
          const position = VctrApi.Utils.lerp(currentState.position, toState.position, timeFraction);
          const target = VctrApi.Utils.lerp(currentState.target, toState.target, timeFraction);
          const zoom = VctrApi.Utils.lerpTwoValues(currentState.zoom, toState.zoom, timeFraction);

          await this.vectaryApi.applyViewState({ target: target, position: position, zoom: zoom });
        });
    },
    moveDown() {
      this.inMove = true;
      this.activeSection--;

      if (this.activeSection < 0) {
        this.activeSection = 0;
        this.inMove = false;
        return;
      }

      this.animateIndex = null;

      this.scrollToSection(this.activeSection, true);
    },
    moveUp() {
      this.inMove = true;
      this.activeSection++;

      if (this.activeSection > this.offsets.length - 1) {
        this.activeSection = this.offsets.length - 1;
        this.inMove = false;
        return;
      }
      this.animateIndex = null;

      this.scrollToSection(this.activeSection, true);
    },
    scrollToSection(id, force = false) {
      if (this.show360 && id !==0) {
        this.show360 = false;
      }
      if (this.inMove && !force) return false;
      this.activeSection = id;
      this.inMove = true;

      setTimeout(() => {
        this.animateIndex = id;
        this.parallaxTransform = "translate3d(0px," + (this.activeSection * -40) / this.offsets.length + "%, 0px)";
        this.parallaxTransform2 = "translate3d(0px," + (this.activeSection * -60) / this.offsets.length +"%, 0px)";


        this.$nextTick(() => {
          document.getElementById("fp").scroll({
            top: this.offsets[id],
            behavior: "smooth",
          });
          this.containerHeight = document.getElementsByTagName("section")[this.activeSection].offsetHeight;

          this.textHeight = this.$refs.anim[id].offsetHeight;
          this.calculateSectionOffsets();
          this.animatePack();
        });
      }, 500);

      setTimeout(() => {
        this.inMove = false;
      }, 1500);
    },
    touchStart(e) {
      this.touchStartY = e.touches[0].clientY;
    },
    touchMove(e) {
      if (this.inMove) return false;

      var isOverflowSection = document.getElementsByTagName("section")[this.activeSection].offsetHeight > window.innerHeight - this.footerHeight;
      var innerOffset = document.getElementsByTagName("section")[this.activeSection].getBoundingClientRect().top;

      const currentY = e.touches[0].clientY;

      if (this.touchStartY < currentY) {
        if (isOverflowSection && Math.round(innerOffset) !== 0) {
          this.onScrollEnd();
          return;
        }
        e.preventDefault();
        this.moveDown();
      } else {
        if (
          isOverflowSection &&
          window.innerHeight < Math.floor(document.getElementById('fp').clientHeight - window.pageYOffset)
        ) {
          this.onScrollEnd();
          return;
        }
        e.preventDefault();
        this.moveUp();
      }

      this.touchStartY = 0;
      return false;
    },
    resizeEvent() {
      this.calculateSectionOffsets();
      this.scrollToSection(this.activeSection, true);
    }
  }
}
