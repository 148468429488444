<template>
  <div class="product-page">
    <div class="left-text">
      <div
        class="left-text__back"
        :style="{
          height: textHeight + 'px',
        }"
      >
        <div
          class="left-text__parallax"
          :style="{
            height: 100 * offsets.length + 'vh',
            transform: parallaxTransform2,
          }"
        ></div>
      </div>
      <div class="left-text__text"></div>
    </div>
    <div
      class="parallax-bg"
      :style="{
        height: 100 * offsets.length + '%',
        transform: parallaxTransform,
      }"
    ></div>
    <div class="sections-next" @click="scrollToSection(activeSection + 1)" v-if="activeSection != offsets.length - 1" :style="{
        marginBottom: textHeight + 'px',
      }">
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
        <path d="M0.788574 0.884466L5.50011 5.86523L10.2117 0.884466" stroke="#20438C" stroke-width="2"/>
      </svg>
    </div>
    <div class="sections-prev" @click="scrollToSection(activeSection - 1)" v-if="activeSection != 0">
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
        <path d="M10.2114 6.78839L5.49989 1.80762L0.788349 6.78839" stroke="#20438C" stroke-width="2"/>
      </svg>
    </div>
    <div
      class="sections-menu"
      :style="{
        marginTop: -(textHeight - headerHeight) / 2 + 'px',
      }"
    >
      <span
        class="menu-point"
        v-bind:class="{ active: activeSection == index }"
        v-on:click="scrollToSection(index)"
        v-for="(offset, index) in offsets"
        v-bind:key="index"
      >
      </span>
    </div>
    <div ref="packOverlay" class="cigarettes-pack-page cigarettes-pack-page-3">
      <!--
      <iframe id="cigarettes-pack-page__model"
              src="https://www.vectary.com/viewer/v1/?model=4319a367-6a03-4180-a217-db6e986a88c5&arAllowScaling=0&lang=ru&showPreloader=1&showPlaceholder=0&showInteractionPrompt=0&zoom=0&pan=0&rotateY=0&doubleClickToFocus=0&env=snowyforest&exposure=10&gamma=1.5"
              allowtransparency="true"
              frameborder="0"
              width="100%"
              height="100%">
      </iframe>
      -->
      <div class="cigarettes-pack-page__placeholder cigarettes-pack-page__placeholder_3"></div>
      <transition name="fade">
        <div class="cigarettes-pack-page__360" v-if="show360"></div>
      </transition>
    </div>
    <div
      id="fp"
      :style="{
        height: containerHeight + 'px',
      }"
    >
      <section
        class="fullpage"
        :class="{active: animateIndex == index}"
        v-for="(item, key, index) in $slots"
        :key="index"
      >
        <div>
          <transition name="slide-left">
            <div
              v-show="animateIndex == index"
              class="animate-block"
              ref="anim"
            >
              <slot :name="key"></slot>
            </div>
          </transition>
          <div v-if="nextButton.url && offsets.length == index + 1" class="_g-hidden-tablet">
            <div class="root-more _product">
              <div class="container">
                <transition name="slide-right">
                  <div v-show="animateIndex == index">
                    <router-link :to="{ path: nextButton.url }" class="button button__lg button__blue button__arrow" v-html="nextButton.text"></router-link>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>

import { VctrApi } from "./../vectary";
import productMixin from "../mixins/productMixin";

export default {
  name: 'ProductPage',
  mixins: [productMixin],
  methods: {
    async animatePack() {

      //изменение высоты на мобилке
      (this.activeSection > 0 && this.activeSection < 4) ?
        this.$refs.packOverlay.classList.add('mobile-rotate'):
        this.$refs.packOverlay.classList.remove('mobile-rotate');

      return; // temporarily disabled while a placeholder is used
      let state = {};
      switch (this.activeSection) {
        case 0:
          state = {
            "target": [-0.0004406095547023481, 0.05877903385319294, -0.001918044853867179],
            "position": [-0.0012263118504406748, 0.07208718114887779, 0.3918454071945966],
            "zoom": 1.25
          };
          break;
        case 1:
          state = {
            "target": [-0.0004406095547023481, 0.07877903385319294, 0.0029180448538671795],
            "position": [-0.24981076488049378, 0.1931067279690376, 0.10744210771662978],
            "zoom": 1.15
          };
          break;
        case 2:
          state = {
            "target": [-0.0004406095547023481, 0.05977903385319294, -0.001918044853867179],
            "position": [0, 0.0769840106627164, 0.33734226558641245],
            "zoom": 1.1
          };
          break;
        case 3:
          state = {
            "target": [-0.0004406095547023481, 0.06877903385319294, -0.001918044853867179],
            "position": [-0.1999359750673202, 0.0769840106627164, 0.33734226558641245],
            "zoom": 1.2
          };
          break;
        case 4:
          state = {
            "target": [-0.0004406095547023481, 0.05877903385319294, -0.001918044853867179],
            "position": [-0.1999359750673202, 0.0769840106627164, 0.33734226558641245],
            "zoom": 1.25
          };
          break;
      }

      // на 4 и 5 экранах открываем пачку, если она закрыта и вытаскиваем сигарету
      if (this.activeSection > 0 && this.activeSection < 4) {
        this.$refs.packOverlay.classList.add('mobile-rotate');
        this.animatePackPosition(state, 1500);
        if (!this.isUp) {
          this.isClosed = false;
          this.isUp = true;
          await this.switchPack(true);
          setTimeout(() => { this.moveCigarette(true)}, 1500);
        }
        return;
      }

      // анимация пачки
      if (!this.isClosed) {
        this.$refs.packOverlay.classList.remove('mobile-rotate');
        this.isClosed = true;
        this.isUp = false;
        await this.moveCigarette(false);
        this.animatePackPosition(state);
        this.switchPack(false);
      } else {
        this.animatePackPosition(state, 1500);
      }
    },
    async moveCigarette(isOpen) {
      return; // temporarily disabled while a placeholder is used
      const cigaretteObjectName = 'Cylinder_4_3';
      const currentPosition = await this.vectaryApi.getPosition(cigaretteObjectName);
      const yPosition = isOpen ? 15.775012493133541 : 1.2912301079029177;

      VctrApi.Utils.animate(
        500,
        "easeInOutQuad",
        async (timeFraction) => {
          const position = VctrApi.Utils.lerp(currentPosition, [currentPosition[0], yPosition, currentPosition[2]], timeFraction);

          await this.vectaryApi.setPositionAbsolute(cigaretteObjectName, position);
        });
    },
    async switchPack(isOpen) {
      return; // temporarily disabled while a placeholder is used
      const capObjectName = 'крышка';
      const bushObjectName = 'вкладыш';
      const allCigObjectName = 'сигареты';
      const cigaretteObjectName = 'Cylinder_4_3';
      const currentRotation = await this.vectaryApi.getRotation(capObjectName);
      const yPosition = isOpen ? 41.93366541345034 : 34.726564007506006;
      const yPositionCig = isOpen ? 34.526337542633616 : 30.526337542633616;
      const yPositionSingleCig = isOpen ? 1.2912301079029177 : -2.6912301079029177;

      setTimeout(async () => {
        const newPosition = await this.vectaryApi.getPosition(bushObjectName);
        const newAllCigPosition = await this.vectaryApi.getPosition(allCigObjectName);
        const newMoveCigPosition = await this.vectaryApi.getPosition(cigaretteObjectName);
        VctrApi.Utils.animate(
          400,
          "easeInOutQuad",
          async (timeFraction) => {
            const position = VctrApi.Utils.lerp(newPosition, [newPosition[0], yPosition, newPosition[2]], timeFraction);
            const cigPosition = VctrApi.Utils.lerp(newAllCigPosition, [newAllCigPosition[0], yPositionCig, newAllCigPosition[2]], timeFraction);
            const singleCigPosition = VctrApi.Utils.lerp(newMoveCigPosition, [newMoveCigPosition[0], yPositionSingleCig, newMoveCigPosition[2]], timeFraction);

            await this.vectaryApi.setPositionAbsolute(bushObjectName, position);
            await this.vectaryApi.setPositionAbsolute(allCigObjectName, cigPosition);
            await this.vectaryApi.setPositionAbsolute(cigaretteObjectName, singleCigPosition);
          });
      }, 100);

      setTimeout(async () => {
        VctrApi.Utils.animate(
          700,
          "easeInOutQuad",
          async (timeFraction) => {
            const rotation = VctrApi.Utils.lerp(currentRotation, [isOpen ? -45 : 44.370000000000005, currentRotation[1], currentRotation[2]], timeFraction);

            await this.vectaryApi.setRotationAbsolute(capObjectName, rotation);
          });
      }, 0)
    },
  },
};
</script>

