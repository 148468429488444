<template>
  <div
    class="modal__wrapper"
    :class="[{ closed: !show }, { 'modal__wrapper_center': alignCenter }]"
    @click="close"
    data-scroll-lock-scrollable
  >
    <div
      class="modal"
      :class="[className, { 'modal_center': alignCenter }]"
      :style="[
        { top: position.top + 'px' },
        { left: position.left + 'px' },
        maxWidth ? { maxWidth: maxWidth + 'px' } : {},
      ]"
      @click.stop
    >
      <div class="modal__close" @click="close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
            fill="#1E418E"
          />
        </svg>
      </div>
      <div class="modal__body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { disablePageScroll, enablePageScroll } from "scroll-lock";

export default {
  name: "Modal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    className: String,
    position: {
      type: Object,
      default: () => ({
        top: 0,
        left: 0,
      }),
    },
    alignCenter: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      showLocal: false,
    };
  },
  watch: {
    show(val) {
      val ? disablePageScroll() : enablePageScroll();
    },
  },
  methods: {
    close() {
      this.$emit("modal:close");
      this.$emit("update:show", false);
    },
  },
};
</script>
